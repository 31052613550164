<template>
  <main class="px-2 sm:px-4 py-4">
    <div
      class="flex flex-row-reverse items-center justify-between sm:flex-col sm:items-start mb-6 sm:mb-0"
    >
      <h4
        class="md:mt-3 md:text-sm md:text-left text-xs font-medium text-dark-600 sm:text-headerText capitalize sm:uppercase"
      >
        Employee Management /
        <span
          class="cursor-pointer"
          @click="$router.push({ name: 'ViewEmployee' })"
          >View Employee</span
        >
        / Archived
      </h4>
      <div class="text-left sm:mt-4 sm:mb-6">
        <easiBackButton link="ViewEmployee"></easiBackButton>
      </div>
    </div>

    <div
      v-if="
        !isSearch &&
        listArchivedEmployees &&
        listArchivedEmployees.data &&
        !listArchivedEmployees.data.length
      "
      class="mt-16 mb-4"
    >
      <easiEmptyState text=" You have not archived any employee yet.">
      </easiEmptyState>
    </div>

    <div v-else class="my-6">
      <!-- <div class="w-full flex justify-end items-center">
        <div
          class="w-fit cursor-pointer text-sm items-center flex justify-start"
        >
          <i class="far fa-clone"></i>
          <span class="ml-2">Archive(d)</span>
        </div>
      </div> -->

      <div
        class="flex flex-col items-center md:flex-row md:items-center mt-4 w-full md:w-full justify-between mx-auto grow-0 gap-4"
      >
        <div class="flex items-center w-full">
          <!-- /////// Search Component ///// -->
          <div class="w-9/12 md:w-6/12">
            <easiSearch
              placeholder="Search employee by name"
              v-model="searchText"
              @search="searchFN"
            />
          </div>

          <!-- /////// Filter Component Start /////// -->
          <div class="ml-4">
            <easiFilter @update="filterFN" />
          </div>
          <!-- /////// Filter Component Ends /////// -->
        </div>

        <div>
          <easiButton
            @click="showUnArchive"
            :loading="loading.unarchive"
            color="secondary"
            class="flex w-32 items-center justify-center gap-2 md:gap-3 px-2 md:py-4"
          >
            <!-- <img class="w-4 md:w-5" src="@/assets/icons/send.svg" alt="" /> -->
            <span class="text-sm md:text-base whitespace-nowrap"
              >Unarchive</span
            >
          </easiButton>
        </div>
      </div>
    </div>

    <div class="mt-8">
      <easiEmployeeTable
        @selected="updateSelectedEmployees"
        :is-search="isSearch"
        :employees="listArchivedEmployees.data"
        :from="'archived'"
      />
    </div>

    <easiWarn
      v-if="unArchiveWarning"
      @close="unArchiveWarning = false"
      :icon="unarchiveIcon"
      showIcon
    >
      <template v-slot:message>
        <span>Are you sure you want to restore Employee?</span>
      </template>
      <template v-slot:action>
        <div class="flex flex-col items-center gap-4">
          <div class="flex justify-center items-center w-full gap-x-4 mt-6">
            <easiButton
              class="w-40 min-w-full bg-white"
              @click="unArchiveWarning = false"
              variant="outlined"
              >Cancel</easiButton
            >
            <easiButton
              class="w-40 min-w-full"
              :loading="loading.unarchive"
              @click="unarchiveEmp"
              >Yes</easiButton
            >
          </div>
        </div>
      </template>
    </easiWarn>

    <easiSuccess v-if="updateSuccess" @close="updateSuccess = false">
      <template v-slot:message>
        <span> Employee Restored Successfully</span>
      </template>
    </easiSuccess>

    <easiRoundLoader v-if="loading.employee" />
  </main>
</template>

<script setup>
import { ref, reactive, onMounted, watch } from "vue";
import { storeToRefs } from "pinia";
import { useDataStore } from "@/stores/data.js";
import { useToast } from "vue-toastification";

import unarchiveIcon from "@/assets/icons/unarchive.svg";

const toast = useToast();
const store = useDataStore();
const { query, set, mutate } = store;
const { listArchivedEmployees, checkLoading } = storeToRefs(store);

let loading = ref({
  employee: false,
  unarchive: false,
});

const updateSuccess = ref(false);
const unArchiveWarning = ref(false);

const searchText = ref("");
let isSearch = ref(false);
const filterOptions = ref({
  sort: { firstName: "ASC" },
  filter: [],
});
let selectedEmployees = ref([]);

const payload = ref({
  archived: true,
  paging: {
    limit: 1000,
    skip: 0,
    search: "",
    sort: {},
  },
  filter: [],
});

watch(searchText, async (val) => {
  // console.log(val.trim(), isSearch.value);
  if (val.trim() === "") {
    payload.value.paging.search = "";
    await queryEmployees();
    isSearch.value = false;
  }
});

onMounted(() => {
  queryEmployees();
});

const searchFN = async (suggested) => {
  // console.log(suggested, searchText.value);
  searchText.value = suggested ? suggested : searchText.value;
  isSearch.value = true;
  payload.value.paging.search = searchText.value;
  await queryEmployees();
};

const filterFN = (arg) => {
  isSearch.value = true;
  filterOptions.value = arg;

  payload.value.paging.sort = filterOptions.value.sort;
  payload.value.filter = filterOptions.value.filter;

  console.log("Payload");
  log(payload.value);
  queryEmployees();
};

const queryEmployees = async () => {
  loading.value.employee = true;
  try {
    await query({
      endpoint: "ListEmployees",
      payload: payload.value,
      service: "EMP",
      storeKey: "listArchivedEmployees",
    });

    loading.value.employee = false;
    console.log("Employees");
    console.log(listArchivedEmployees.value.data);
  } catch (err) {
    loading.value.employee = false;
    console.log(err);
  }
};

function updateSelectedEmployees(arg) {
  selectedEmployees.value = [];
  selectedEmployees.value = arg;
}

function showUnArchive() {
  if (selectedEmployees.value.length < 1) {
    toast.error("You have to select an employee");
    return false;
  } else {
    unArchiveWarning.value = true;
  }
}

const unarchiveEmp = async () => {
  if (selectedEmployees.value.length < 1) {
    toast.error("You have to select an employee");
    return false;
  }

  loading.value.unarchive = true;

  let res;

  try {
    res = await mutate({
      endpoint: "UnarchiveEmployee",
      data: { employeeIds: selectedEmployees.value },
      service: "EMP",
    });
  } catch (err) {
    log(err);
    loading.value.unarchive = false;
  }
  loading.value.unarchive = false;

  console.log(res);
  if (res && res.success) {
    await queryEmployees();
    toast.success(res.message);
    unArchiveWarning.value = false;
    updateSuccess.value = true;
    updateSelectedEmployees([]);

    setTimeout(() => {
      updateSuccess.value = false;
    }, 700);
  }
};

// Get rid of the proxy when console.log
function log(arg) {
  console.log(JSON.parse(JSON.stringify(arg)));
}
</script>

<style></style>
